@use "../../index";
@use "../../animations";

.title-card .skills-list-box
{
    animation: fade-in 0.4s ease-in;
    border-radius: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 1rem;
}